import React from 'react';
import './skills.css';
import htmlIcon from './Images/html.png';
import cssIcon from './Images/css.png';
import jsIcon from './Images//js.gif';
import reacticon from './Images/react1.gif'
import nodeicon from './Images/nodejs.png'
import expressicon from './Images/expjs.png'
import mongoicon from './Images/mongodb.png'
import tjsicon from './Images/3js.jpg'
import pyicon from './Images/python.gif'

const Skill = () => {
  return (
    <div className='skill-container' id='skills'>
      <h1 className='skill-heading'>Language Skills</h1>
      <div className='skills'>
        <div className='skill'>
          <img src={htmlIcon} alt='HTML' className='skill-icon' />
          <p>HTML</p>
        </div>
        <div className='skill'>
          <img src={cssIcon} alt='CSS' className='skill-icon' />
          <p>CSS</p>
        </div>
        <div className='skill'>
          <img src={jsIcon} alt='JavaScript' className='skill-icon' />
          <p>JavaScript</p>
        </div>
        <div className='skill'>
          <img src={pyicon} alt='JavaScript' className='skill-icon' />
          <p>Python</p>
        </div>
        <div className='skill'>
          <img src={reacticon} alt='JavaScript' className='skill-icon' />
          <p>React</p>
        </div>
        <div className='skill'>
          <img src={nodeicon} alt='JavaScript' className='skill-icon' />
          <p>NodeJS</p>
        </div>
        <div className='skill'>
          <img src={expressicon} alt='JavaScript' className='skill-icon' />
          <p>ExpressJS</p>
        </div>
        <div className='skill'>
          <img src={mongoicon} alt='JavaScript' className='skill-icon' />
          <p>MongoDB</p>
        </div>
        <div className='skill'>
          <img src={tjsicon} alt='JavaScript' className='skill-icon' />
          <p>ThreeJS</p>
        </div>
        
        
      </div>
    </div>
  );
}

export default Skill;
