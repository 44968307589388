import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';
import linkedinicon from './Images/icons8-linkedin-1500.png';
import githubicon from './Images/icons8-github-96.png';
import twittericon from './Images/icons8-twitter-circled-94.png';
import igicon from './Images/icons8-instagram-1500.png';

export const Contact = () => {
  const form = useRef();
  const [showNotification, setShowNotification] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
  
    const formData = new FormData(form.current);
    const data = Object.fromEntries(formData);
  
    emailjs
      .sendForm('service_d1tc3u8', 'template_4o421a9', form.current, {
        publicKey: 'T0chTqPn7cySP6SxG',
        from_name: data.user_name,
        to_name: "Vijay",
        from_email: data.user_email,
        message: data.message,
      })
      
      .then(
        () => {
          console.log('SUCCESS!');
          console.log(data);
          setShowNotification(true); 
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className='skill-container' id='contact'>
      <h1 className='skill-heading'>To Contact Me</h1>
      {showNotification && <p className="notification">Form submitted successfully!</p>}
      <div className='skills'>
        <div className='skill'>
          <a href='https://www.linkedin.com/in/vijay-viswalingam-4b167326a' target='__blank'><img src={linkedinicon} alt='linkedin' className='skill-icon' /></a>
        </div>
        <div className='skill'>
          <a href='https://github.com/vijayvmr2710' target='__blank'><img src={githubicon} alt='github' className='skill-icon' /></a>
        </div>
        <div className='skill'>
          <a href='https://twitter.com/yakob5575?t=_Plpo2TIWQJtSlWW2VPBdA&s=09' target='__blank'><img src={twittericon} alt='twitter' className='skill-icon' /></a>
        </div>
        <div className='skill'>
          <a href='https://www.instagram.com/vijay_vmr?igsh=bXUzODEzcGU4eTly' target='__blank'><img src={igicon} alt='instagram' className='skill-icon' /></a>
        </div>
      </div>
      <form ref={form} onSubmit={sendEmail} className="form-container">
        <h2 className="form-heading"></h2>
        <div className="form-input">
          <label>Name</label>
          <input type="text" name="user_name" />
        </div>
        <div className="form-input">
          <label>Email</label>
          <input type="email" name="user_email" />
        </div>
        <div className="form-input">
          <label>Message</label>
          <textarea name="message" />
        </div>
        <div className="form-submit">
          <input type="submit" value="Send" />
        </div>
      </form>
      <div className="footer">
        <h4>Copyright 2024 © VIJAY PORTFOLIO </h4>
        <h4>Design and Coded By Vijay</h4>
      </div>
    </div>
  );
}

export default Contact;
