import React, { useEffect, useRef } from 'react';
import './about.css';

const About = () => {
  const aboutRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const aboutElement = aboutRef.current;
      const position = aboutElement.getBoundingClientRect().top;

      if (position < window.innerHeight * 0.75) {
        aboutElement.classList.add('animate');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={aboutRef} className='about-container' id='about'>
      <h1 className='head'>Who I am?</h1>
      <div  className='about'>
        <div className='intro'>
        <h1 className='abme'>About Me</h1>
          I'm Vijay, an aspiring Full Stack/MERN Stack developer eager to embark on a journey in the world of web development. While I may be new to the field professionally, my passion for coding and dedication to mastering new technologies drive my pursuit of becoming a proficient developer.
        </div>
        <div className='journey'>
        <p className='box1'>My Journey</p>
        My journey into the world of web development began with a curiosity-driven exploration of coding languages and frameworks. Over time, this curiosity blossomed into a deep-seated passion for building web applications that are not only functional but also visually appealing and intuitive to use.
        </div>
      </div>
    </div>
  );
}

export default About;