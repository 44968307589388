import React, { useState, useEffect } from 'react';
import './home.css';

const Home = () => {
  const [developerText, setDeveloperText] = useState("Vijay");

  useEffect(() => {
    const interval = setInterval(() => {
      setDeveloperText((prevText) =>
        prevText === "Vijay" ? "Fullstack Web Developer" : "Vijay"
      );
    }, 2500); 

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='deta' id='home'>
      <div className='content'>
        <p>HELLO!</p>
        <br />
        {developerText === "Vijay" ? (
          <>
            <span className='info1'>I'm</span>
            <br />
            <span className='info2'>{developerText}</span>
          </>
        ) : (
          <>
            <span className='info1'>I'm a</span>
            <br />
            <span className='info2'>{developerText}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
