import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';

import Home from './Components/Home';
import About from './Components/About';
import Project from './Components/Projects';
import Skill from './Components/Skills';
import Contact from './Components/Contact';

function App() {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="App">
      <header className="header">
        <div className='title' ><a href='https://www.vijaytechs.com/'>VIJAY</a></div>
        <nav className='roles'>
          <button onClick={() => scrollToSection('home')}>Home</button>
          <button onClick={() => scrollToSection('about')}>About</button>
          <button onClick={() => scrollToSection('skills')}>Skills</button>
          <button onClick={() => scrollToSection('project')}>Projects</button>
          <button onClick={() => scrollToSection('contact')}>Contact</button>
          <a href='/resume/VijayResume.pdf' className='download' target='_blank' rel='noopener noreferrer'>DownloadCV</a>

        </nav>
      </header>

      <div id="home">
        <Home />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="skills">
        <Skill />
      </div>
      <div id="project">
        <Project />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  );
}

export default App;
