import React, { useState, useEffect } from 'react';
import './project.css';

const Project = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const projectData = [
        {
          id: 1,
          title: 'Youtube Clone',
          description: 'Designed a website like Youtube which dont have any AD, Well maintained User Interface ,Video Layouts in and for the data integrated Youtube V3 api.Used React for frontend and Expressjs, Nodejs for the backend.',
          imageUrl: 'Images/vmrtube.jpg',
          url: 'https://vmrtube.vercel.app',
        },
        {
          id: 2,
          title: 'VMR BUY',
          description: 'A simple E-commerce website which displays product details.',
          imageUrl: 'Images/vmrbuy.jpg',
          url: 'https://vmrbuy.vercel.app',
        },
        {
          id: 3,
          title: 'Pictify',
          description: 'Pictify is an AI Image Generator which generates images by providing prompts.',
          imageUrl: 'Images/pictify.jpg',
          url: 'https://pictifyai.vercel.app',
        },
        {
          id: 4,
          title: 'Weather App',
          description: 'Real Time weather update website shows details of all Regions',
          imageUrl: 'Images/weatherapp.jpg',
          url: 'https://vmr-weather-app.vercel.app',
        },
      ];

      
      
      const projectsWithImages = await Promise.all(projectData.map(async (project) => {
        const imageModule = await import(`../Components/${project.imageUrl}`);
        return { ...project, imageUrl: imageModule.default };
      }));

      setProjects(projectsWithImages);
    };

    fetchProjects();
  }, []);

  return (
    <div className='project-container' id='project'>
      <div className="title-container">
        <h1 className="my-projects">See My Works</h1>
      </div>
      <div className='project-boxes'>
      {projects.map((project) => (
  <div key={project.id} className='project-box' onClick={() => window.open(project.url, '_blank')}>
    <div className="project-details-container">
      <img src={project.imageUrl} alt={project.title} />
      <div className='project-description'>
        <h2>{project.title}</h2>
        <p>{project.description}</p>
      </div>
    </div>
  </div>
))}

      </div>
    </div>
  );
}

export default Project;
